import "./Footbar.scss";
import { IStructureElement } from "../../../../types/IStructureElement";
import Footer from "../../BlockElement/Footer/Footer";
import Image from "../../MediaElement/Image/Image";
import logo from "../../../../assets/logo.png";
import Container from "../../BlockElement/Container/Container";
import Span from "../../TextElement/Span/Span";
import { FloatButton } from "antd";
import insta from '../../../../assets/insta.svg';
import fb from '../../../../assets/fb.svg';

export default function Footbar({ id, className }: IStructureElement) {
  return (
    <Footer id={id} className={className}>
      <Container className="w-full bg-sp-light-pink py-[25px]">
        <Container className="w-full flex items-center justify-center">
          <Image src={logo} alt="Logo" className="h-[60px]" />
        </Container>

        <Container className="flex items-center justify-center px-10">
          <Span className="font-lato pt-3 text-xs md:text-sm text-center" items={[]} htmlFor="">EURO-FRYZ S.C., wyłączny importer marki Sweet Professional w Polsce</Span>
        </Container>

        <FloatButton.Group shape="circle"  style={{ bottom: 24, right: 15, insetInlineEnd: 24 }}>
          <FloatButton icon={<Image src={insta} alt="Instagram"/>} href="https://www.instagram.com/sweetprofessional.pl/?__d=1"/>
          <FloatButton icon={<Image src={fb} alt="Facebook"/>} href="https://www.facebook.com/SweetProfessionalPolska/"/>
        </FloatButton.Group>
      </Container>
    </Footer>
  );
}
