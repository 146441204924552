
import ComparisonSlider from "../../../components/ComparisonSlider/ComparisonSlider";
import JoinUs from "../../../components/JoinUs/JoinUs";
import Container from "../../../components/Main/BlockElement/Container/Container";
import Page from "../../../components/Main/StructureElement/Page/Page";
import ProductHeader from "../../../components/Product/ProductHeader/ProductHeader";
import ProductsClientHairdresser from "../../../components/Product/ProductsClientHairdresser/ProductsClientHairdresser";
import ProductsFormula from "../../../components/Product/ProductsFormula/ProductsFormula";
import { data } from "../../../utils/lovely";

export default function Lovely() {
  return (
    <Page>
      <ProductHeader {...data.header} />
      <ProductsClientHairdresser {...data.productsHairdresser} />
      <ProductsClientHairdresser {...data.productsClient} />
      <ProductsFormula {...data.productsFormula} />
      <ComparisonSlider {...data.comparison} />
      <JoinUs {...data.joinUs} />
      <Container className={"md:mt-44"} />
    </Page>
  );
}
