import { IStructureElement } from "../../../../types/IStructureElement";
import Container from "../../BlockElement/Container/Container";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

export default function Navbar({ id, className }: IStructureElement) {
  return (
    <>
      {/* Desktop Menu */}
      <Container className="hidden md:block fixed top-0 left-0 w-full z-50">
        <NavbarDesktop />
      </Container>

      {/* Mobile Menu */}
      <Container className="block md:hidden fixed top-0 left-0 w-full z-50">
        <NavbarMobile />
      </Container>
    </>
  );
}
