import React from "react";
import sweetAkademia from "../../assets/sweetAkademia.png";
import { useNavigate } from "react-router-dom";
import Section from "../Main/BlockElement/Section/Section";
import Animation from "../Animation/Animation";
import About from "../About/About";

export default function SweetAcademyShort() {
  const navigate = useNavigate();

  return(
    <Animation>
      <Section className="mx-5 mt-[100px] md:mt-[150px]">
        <About
          title="SWEET AKADEMIA"
          content={`Sweet Academy to miejsce, w którym zdobędziesz wiedzę \njak prawidłowo korzystać z produktów i wdrażać je w swoim \nsalonie. Oferujemy zarówno szkolenia jak i szkolenia online, \npodczas których nasi trenerzy zapewnią Ci wszystko, czego \npotrzebujesz, aby cieszyć się fenomenalnymi efektami \nnaszych nowoczesnych kosmetyków`}
          image={sweetAkademia}
          imgPosition="right"
          onClick={() => navigate(`/akademia`)}
          textButton="Odkryj teraz"
        />
      </Section>
    </Animation>
  );
}
