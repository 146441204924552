import { IAbout } from "../../types/IAbout";
import Container from "../Main/BlockElement/Container/Container";
import { Button } from "../Main/FormElement/Button";
import Image from "../Main/MediaElement/Image/Image";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";

export default function About({ title, content, image, onClick, textButton, imgPosition = "left" }: IAbout) {
    const isImageLeft = imgPosition === "left";

    const picture = (
        <Container className="w-full">
            <Container className="flex flex-col items-stretch justify-center w-full">
                <Image src={image} alt={title} className="w-full"/>
            </Container>
        </Container>
    );

    return (
        <Container className="flex flex-col md:flex-row items-center justify-center gap-[4dvw]">
            <Container className="block md:hidden w-full md:w-1/2">{picture}</Container>
            {isImageLeft && <Container className="hidden md:block w-full md:w-1/2">{picture}</Container>}

            <Container className={`w-full md:w-1/2 ${isImageLeft ? "md:pr-10" : "md:pl-10"}`}>
                <Container className={`flex flex-col items-stretch justify-center w-full md:px-10 text-center py-5 ${isImageLeft ? "md:text-left" : "md:text-right"}`}>
                    <Heading type="h3" htmlFor="" items={[]} className={`font-playfair text-2xl md:text-4xl font-medium ${title === "SWEET AKADEMIA" ? "md:text-left" : ""}`}>{title}</Heading>
                    <Paragraph htmlFor="" items={[]} className={`whitespace-pre-wrap font-playfair text-sm md:text-xl font-light mt-5 ${title === "SWEET AKADEMIA" ? "md:text-left" : ""}`}>{content}</Paragraph>
                    {onClick && (
                        <Container className={`mt-5 ${title === "SWEET AKADEMIA" ? "md:text-left" : ""}`}>
                            <Button className="border-sp-dark hover:bg-sp-sweet-pink hover:text-white hover:border-sp-sweet-pink" onClick={onClick}>
                                {textButton ?? ""}
                            </Button>
                        </Container>
                    )}
                </Container>
            </Container>

            {!isImageLeft && <Container className="hidden md:block w-full md:w-1/2">{picture}</Container>}
        </Container>
    );
}
