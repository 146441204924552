import { useState } from "react";
import Container from "../../components/Main/BlockElement/Container/Container";
import Section from "../../components/Main/BlockElement/Section/Section";
import Image from "../../components/Main/MediaElement/Image/Image";
import Page from "../../components/Main/StructureElement/Page/Page";
import Label from "../../components/Main/TextElement/Label/Label";
import { Button } from "../../components/Main/FormElement/Button";
import signup from '../../assets/signup.png';
import Heading from "../../components/Main/TextElement/Heading/Heading";
import { useNavigate } from "react-router-dom";

export default function SignUp(){
    const [nip, setNip] = useState(''); 
    const [email, setEmail] = useState(''); 
    const [password, setPassword] = useState(''); 

    const navigate = useNavigate();
    
    return(
        <Page className="min-h-[140dvh] md:min-h-[120dvh] flex justify-center items-center">
            <Section className="flex flex-row justify-space-between bg-sp-light-pink w-4/5 md:w-3/5">
                <Container className="w-0 md:w-1/2">
                    <Image src={signup} alt="Dołącz do Nas" className="" />
                </Container>
                <Container className="w-full md:w-1/2 flex flex-col justify-center items-center p-5 md:p-10 bg-sp-light-pink">
                    <Heading type="h1" items={[]} htmlFor="" className="font-playfair text-2xl text-sp-black font-semibold uppercase">Zarejestruj się</Heading>

                    <Container className="w-full flex flex-col gap-2 mt-5">
                        <Label items={[]} htmlFor="nip" className="font-playfair text-lg text-sp-black">NIP*</Label>
                        <input name="nip" type="text" value={nip} onChange={(e) => setNip(e.target.value)} className="w-full p-2" />
                    </Container>

                    <Container className="w-full flex flex-col gap-2 mt-5">
                        <Label items={[]} htmlFor="email" className="font-playfair text-lg text-sp-black">E-mail*</Label>
                        <input name="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full p-2" />
                    </Container>

                    <Container className="w-full flex flex-col gap-2 mt-5">
                        <Label items={[]} htmlFor="password" className="font-playfair text-lg text-sp-black">Hasło*</Label>
                        <input name="password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full p-2" />
                    </Container>

                    <Container className="w-full flex flex-col gap-2 mt-5">
                        <Label items={[]} htmlFor="password" className="font-playfair text-lg text-sp-black">Powtórz Hasło*</Label>
                        <input name="password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full p-2" />
                    </Container>

                    <Container className="w-full flex flex-row items-start gap-4 mt-5">
                        <input name="isStatute" type="checkbox" className="mt-2" />
                        <Label items={[]} htmlFor="isStatute" className="font-playfair text-md text-sp-black">
                            Zapoznałem się z regulaminem sweetprofessional.pl oraz polityką prywatności i akceptuję ich treść.*
                        </Label>
                    </Container>

                    <Container className="w-full flex flex-row items-start gap-4 mt-5">
                        <input name="isMarketing" type="checkbox" className="mt-2" />
                        <Label items={[]} htmlFor="isMarketing" className="font-playfair text-md text-sp-black">
                            Chcę otrzymywać od sweetprofessional.pl na podany przeze mnie adres 
                            e-mail, informacje marketingowe dotyczące sweetprofessional.pl
                        </Label>
                    </Container>

                    <Container className="flex flex-col justify-center items-center w-full gap-5 mt-10">
                        <Button className={`bg-sp-sweet-pink text-white hover:bg-opacity-90 border-none py-3 w-full`}>Zarejestruj się</Button>
                        <Button className="w-full rounded rounded-3xl border border-sp-dark p-[8px] px-[35px] hover:shadow-lg hover:bg-sp-sweet-pink hover:text-white hover:border-sp-sweet-pink transform transition duration-900 ease-in-out" onClick={() => navigate('/auth/signin')}>Masz już konto? Zaloguj się</Button>
                    </Container>
                </Container>
            </Section>
        </Page>
    );
}