import Animation from "../Animation/Animation";
import Container from "../Main/BlockElement/Container/Container";
import { qualities } from "../../utils/qualities";
import Section from "../Main/BlockElement/Section/Section";
import Image from "../Main/MediaElement/Image/Image";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";
import Heading from "../Main/TextElement/Heading/Heading";

export default function Quality() {

  const steps = qualities.map((el, index) => (
    <Container
      key={el.title}
      className={`w-2/3 md:w-full p-4 md:p-0
        ${index % 2 === 0 ? "self-start" : "self-end"} 
        ${el.paddingTop ? "md:pt-[150px]" : ""}
      `}
    >
      <Container className="flex flex-col items-center">
        <Image src={el.img} className="w-full -mb-6 md:-mb-9" />
        <Container className="w-full text-left pl-6">
          <Heading type='h3' className="font-playfair md:text-2xl md:text-3xl text-sp-sweet-pink font-bold" items={[]} htmlFor="">{el.title}</Heading>
          <Paragraph className="font-playfair text-sm md:text-xl pt-3" items={[]} htmlFor="">{el.content}</Paragraph>
        </Container>
      </Container>
    </Container>
  ));

  return (
    <Animation>
      <Section className="py-[50px]">
        <Container className="flex flex-col md:flex-row md:justify-center gap-10 md:gap-[75px] md:px-[100px]">{steps}</Container>
      </Section>
    </Animation>
  );
}