import { IProduct } from "../types/IProduct";
import { Drink } from "../components/Product/ProductIcon/Drink/Drink";
import header from '../assets/products/alquimia.jpg';
import proteins from '../assets/products/lines/alquimia/proteiny.png';
import oil from '../assets/products/lines/alquimia/oleje.png';
import kumaryna from '../assets/products/lines/alquimia/kumaryna.png';
import turmalin from '../assets/products/lines/alquimia/turmalin.png';
import before from '../assets/products/lines/alquimia/alquimia1.jpg';
import after from '../assets/products/lines/alquimia/alquimia2.jpg';
import ampulkakeratyna from '../assets/products/lines/alquimia/ampulkakeratyna.png';
import ampulkaoleje from '../assets/products/lines/alquimia/ampulkaoleje.png';
import ampulkarekonstrukcja from '../assets/products/lines/alquimia/ampulkarekonstrukcja.png';
import ampulkautrwalajaca from '../assets/products/lines/alquimia/ampulkautrwalajaca.png';
import efervescente from '../assets/products/lines/alquimia/efervescente.png';
import maskajelly from '../assets/products/lines/alquimia/maskajelly.png';
import { ReactNode } from "react";

export const data: IProduct = {
    header: {
        image: header,
        name: "Alquimia",
        nameDescription: "Alchemia pięknych włosów",
        description: `Linia zabiegów personalizowanych z ampułkami. Każdy profesjonalista może stać się kreatywnym alchemikiem, tworząc skuteczne i ekskluzywne rozwiązania, które podkreślają piękno i zdrowie włosów.`,
        primaryColor: "sp-alquimia-primary",
        secondaryColor: "sp-alquimia-secondary",
        circle: {
            color: "#9770BE",
            icon: <Drink color={"#9770BE"} />,
            text1: <>PERSONALIZACJA</> as unknown as ReactNode,
            text2: <>BLASK I ODŻYWIENIE</> as unknown as ReactNode,
            text3: <>REGENERACJA</> as unknown as ReactNode,
        }
    },
    line: {
        title: "",
        description: "",
        additionalDescription: "",
        primaryColor: "",
        secondaryColor: "",
        product1: {
            image: "",
            title: "",
            special: false,
            description: '',
            capacity: "",
            primaryColor: "",

        },
        product2: {
            image: "",
            title: "",
            special: false,
            description: '',
            capacity: "",
            primaryColor: "",

        },
        product3: {
            image: "",
            title: "",
            special: false,
            description: '',
            capacity: "",
            primaryColor: "",
        },
    },
    productsFormula: {
        ingredients: [
            {
                image: proteins,
                name: "Proteiny",
            },
            {
                image: kumaryna,
                name: "Kumaryna",
            },
            {
                image: turmalin,
                name: "Turmalin",
            },
            {
                image: oil,
                name: "Oleje",
            },
        ]
    },
    comparison: {
        title: "EFEKTY STOSOWANIA",
        content: "Po licznym rozjaśnianiu i na powrót pofarbowaniu włosów na czarno, włosy modelki utraciły blask i stały się słabe. Po zastosowaniu koktajlu Alchemy, włosy odzyskały blask, zdrowie i sprężystosć.",
        imageBefore: before,
        imageAfter: after,
        primaryColor: "sp-alquimia-primary",
        secondaryColor: "sp-alquimia-secondary",
        arrowsColor: "#F0EDF2"
    },
    joinUs: {
        title: "CHCESZ MIEĆ LINIĘ ALQUIMIA W SWOIM SALONIE?",
        content: "Zarejestruj swój salon i uzyskaj możliwość zakupu produktów oraz dostęp do Akademii Sweet Professional z naszymi autorskimi kursami.",
        primaryColor: "sp-alquimia-primary",
        secondaryColor: "sp-alquimia-secondary",
    },
    productsHairdresser: {
        title: <>PRODUKTY DO SALONÓW</> as unknown as ReactNode,
        description: "Nasze produkty są przeznaczone dla profesjonalistów, dlatego nabyć je mogą jedynie salony fryzjerskie. Jednak część z produktów jest również dostępna do pielęgnacji domowej dla klientek.",
        additionalDescription: "",
        primaryColor: "sp-alquimia-primary",
        secondaryColor: "sp-alquimia-secondary",
        descriptionPosition: "header",
        products: [
            {
                image: ampulkaoleje,
                title: "Ampułka Odżywcze Oleje",
                special: false,
                description: "Ampułka do zabiegów, przeznaczona dla wszystkich typów włosów, szczególnie wysuszonych. Odżywia włosy i nadaje im blask. Głęboko nawilża włosy, przywracając ich gładkość i redukując puszenie się. Działa także przeciwstarzeniowo i chroni przed uszkodzeniami spowodowanymi promieniowaniem UVB. ",
                capacity: "15ml",
                primaryColor: "sp-alquimia-primary",
            },
            {
                image: ampulkakeratyna,
                title: "Ampułka Keratyna",
                special: false,
                description: "Nasza ampułka keratynowa jest przeznaczona dla wszystkich typów włosów, przywracając im intensywny blask i witalność. Głęboko odżywia włosy, uzupełniając niezbędne składniki odżywcze utracone w wyniku procesów chemicznych i mechanicznych.",
                capacity: "15ml",
                primaryColor: "sp-alquimia-primary",
            },
            {
                image: ampulkautrwalajaca,
                title: "Ampułka Utrwalająca Kolor",
                special: false,
                description: "Ampułka do zabiegów, przeznaczona dla włosów farbowanych. Chroni kolor i zwiększa blask włosów. Utrzymuje kolor, wspomaga zachowanie pigmentu oraz wzmacnia odcienie i blask włosów. Farbowane włosy pozostaną długo chronione.",
                capacity: "15ml",
                primaryColor: "sp-alquimia-primary",
            },
            {
                image: ampulkarekonstrukcja,
                title: "Ampułka Rekonstrukcja",
                special: false,
                description: "Ampułka do zabiegów, przeznaczona dla zniszczonych włosów. Przywraca włosom odporność i elastyczność. Bogata w nawilżające składniki aktywne. Włosy pozostają głęboko odżywione, z zdrowym i pełnym życia wyglądem",
                capacity: "15ml",
                primaryColor: "sp-alquimia-primary",
            },
            {
                image: efervescente,
                title: "Efervescente",
                special: false,
                description: "Pianka nawilża, oraz chroni przed uszkodzeniami chemicznymi i fizycznymi. Może być stosowana samodzielnie jako odżywka i jako dodatek do koktajlu Alquemia. Zwiększa objętość produktów, z którymi zostanie zmieszana i wzmacnia ich działanie.",
                capacity: "200ml",
                primaryColor: "sp-alquimia-primary",
            },
            {
                image: maskajelly,
                title: "Maska Jelly",
                special: false,
                description: "Pianka nawilża, oraz chroni przed uszkodzeniami chemicznymi i fizycznymi. Może być stosowana samodzielnie jako odżywka i jako dodatek do koktajlu Alquemia. Zwiększa objętość produktów, z którymi zostanie zmieszana i wzmacnia ich działanie.",
                capacity: "200ml",
                primaryColor: "sp-alquimia-primary",
            },
        ]
    },
    productsClient: {
        title: <></> as unknown as ReactNode,
        description: "",
        additionalDescription: "",
        primaryColor: "sp-alquimia-primary",
        secondaryColor: "sp-alquimia-secondary",
        descriptionPosition: "header",
        products: []
    }
};
