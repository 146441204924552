import Page from "../../components/Main/StructureElement/Page/Page";
import JoinUs from "../../components/JoinUs/JoinUs";
import ContactHeader from "../../components/ContactHeader/ContactHeader";
import ContactFormAndDetails from "../../components/ContactFormAndDetails/ContactFormAndDetails";

export default function Contact() {

    return (
        <Page className="pt-[60px] md:pt-[100px] w-full bg-white">
            <ContactHeader /> 
            <ContactFormAndDetails />
            <JoinUs
                title="DOŁĄCZ DO ŚWIATA SWEET PROFESSIONAL"
                content="Zarejestruj swój salon i uzyskaj dostęp do Akademii Sweet Professional z naszymi autorskimi kursami i możliwością zakupu produktów."
                primaryColor="sp-sweet-pink"
                secondaryColor="sp-light-pink"
            />
        </Page>
    );
}