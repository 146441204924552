import Animation from "../Animation/Animation";
import Section from "../Main/BlockElement/Section/Section";
import Container from "../Main/BlockElement/Container/Container";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";
import Image from "../Main/MediaElement/Image/Image";
import { IComparisonSlider } from "../../types/IComparisonSlider";
import { useSlider } from "../../hooks/useSlider";
import Arrows from "./Arrows/Arrows";

export default function ComparisonSlider({
  title,
  content,
  imageBefore,
  imageAfter,
  primaryColor,
  secondaryColor,
  arrowsColor,
}: IComparisonSlider) {
  
  const { sliderPosition, handleMove, handleTouchMove, handleMouseUp, handleMouseDown } = useSlider();

  const header = (
    <Container className="flex flex-col items-stretch justify-center w-full">
      <Heading type="h2" htmlFor="" items={[]} className="font-playfair text-xl md:text-4xl text-sp-black text-center md:text-left font-medium ml-5 md:ml-14">{title}</Heading>
      <Paragraph htmlFor="" items={[]} className="font-playfair md:text-xl text-sp-black text-left ml-5 font-light md:ml-14 mr-5 md:mr-14 mt-5 whitespace-pre-wrap">{content}</Paragraph>
    </Container>
  );

  const slider = (
    <div
      className="relative md:right-52 md:bottom-24 w-full m-auto overflow-hidden select-none"
      onMouseMove={handleMove}
      onTouchMove={handleTouchMove}
      onMouseDown={handleMouseDown}
    >
      <Image src={imageAfter} alt="Hair after effect picture" />
      
      <div className="absolute top-0 left-0 right-0 w-full m-auto overflow-hidden select-none" style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}>
        <Image src={imageBefore} alt="Hair before effect picture" />
      </div>
      
      <div className={`absolute top-0 bottom-0 w-1 bg-${primaryColor} cursor-ew-resize`} style={{left: `calc(${sliderPosition}% - 1px)`}}>
        <Container className={`flex font-playfair text-${secondaryColor} font-bold text-2xl bg-${primaryColor} absolute rounded-full h-11 w-11 justify-center items-center text-center align-middle -left-5 top-[calc(50%-5px)]`}>
          <Arrows color={arrowsColor} />
        </Container>
      </div>
    </div>
  );

  return(
    <Animation>
      <Section className="w-full flex flex-col md:flex-row md:my-32">
        <Container className="flex flex-col w-full md:w-7/12 relative p-2 md:pl-40 md:pr-52 py-5 md:py-40">{header}</Container>
        <Container className="flex flex-col items-stretch justify-center w-0 md:w-1/12"></Container>
        <div className={`flex flex-col w-full md:w-4/12 relative mt-10 md:mt-0 -mr-0 md:-mr-28 bg-${secondaryColor} p-5 md:p-0`} onMouseUp={handleMouseUp}>{slider}</div>
      </Section>
    </Animation>
  );
}
