import ProductCard from "../ProductCard/ProductCard";
import ProductHeadline from "../ProductHeadline/ProductHeadline";
import Container from "../../Main/BlockElement/Container/Container";
import TextCard from "../../TextCard/TextCard";
import { IProductsClientHairdresser } from "../../../types/IProductsClientHairdresser";
import Animation from "../../Animation/Animation";
import Section from "../../Main/BlockElement/Section/Section";
import { IProductCard } from "../../../types/IProductCard";

export default function ProductsClientHairdresser({
  title,
  description,
  additionalDescription,
  primaryColor,
  descriptionPosition,
  products,
}: IProductsClientHairdresser) {

  const header = (
    <Container className="md:mx-40">
      <ProductHeadline
        title={title}
        description={description}
        additionalDescription={additionalDescription}
        primaryColor={primaryColor}
      />
    </Container>
  );

  return (
    <Animation>
        <Section className="my-6">
          {descriptionPosition === "header" && header}
          <Container id="5" className="flex flex-wrap md:px-40 flex-col md:flex-row">
            {descriptionPosition === "before" && <TextCard title={title} description={description} />}
            {products.map((product: IProductCard) => (<ProductCard {...product} />))}
            {descriptionPosition === "after" && <TextCard title={title} description={description} />}
          </Container>
        </Section>
    </Animation>
  );
}
