import { IProductHeadLine } from "../../../types/IProductHeadline";
import Container from "../../Main/BlockElement/Container/Container";
import Heading from "../../Main/TextElement/Heading/Heading";
import Paragraph from "../../Main/TextElement/Paragraph/Paragraph";

export default function ProductHeadline({ title,description,additionalDescription,primaryColor }: IProductHeadLine) {
  return (
    <Container className="flex flex-col justify-center w-full p-10">
      <Heading type="h2" items={[]} htmlFor={""} className="font-playfair text-xl md:text-4xl text-sp-black text-left font-medium uppercase">{title}</Heading>
      <Paragraph items={[]} htmlFor={""} className="font-playfair text-sm md:text-lg text-sp-black text-left pt-3">{description}</Paragraph>
      <Paragraph items={[]} htmlFor={""} className={`font-playfair text-sm md:text-lg text-left pt-3 text-${primaryColor} font-bold`}>{additionalDescription}</Paragraph>
    </Container>
  );
}
