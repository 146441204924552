import Headline from "../../components/Headline/Headline";
import Container from "../../components/Main/BlockElement/Container/Container";
import Page from "../../components/Main/StructureElement/Page/Page";
import SweetAcademy from "../../components/SweetAcademy/SweetAcademy";

export default function Academy() {
    return (
        <Page className="pt-[125px]">
            <SweetAcademy/>
            <Container className="md:w-1/2 m-5 md:mx-auto py-10 bg-sp-light-pink">
                <Headline title="Już wkrótce..." content="Już niedługo pojawi się więcej informacji o szkoleniach stacjonarnych, a także wyjątkowa platforma ze szkoleniami online."/>
            </Container>
        </Page>
    );
}
