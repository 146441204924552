import { ReactNode } from "react";
import { IProduct } from "../types/IProduct";
import { Leaf } from "../components/Product/ProductIcon/Leaf/Leaf";
import header from '../assets/products/detox.png';
import before from '../assets/products/lines/detox/detox1.jpg';
import after from '../assets/products/lines/detox/detox2.jpg';
import detoxingredient1 from '../assets/products/lines/detox/detoxingredient1.png';
import detoxingredient2 from '../assets/products/lines/detox/detoxingredient2.png';
import detoxingredient3 from '../assets/products/lines/detox/detoxingredient3.png';
import detoxingredient4 from '../assets/products/lines/detox/detoxingredient4.png';
import detoxvegangel from '../assets/products/lines/detox/detoxvegangel.png';
import detoxveganodzywka from '../assets/products/lines/detox/detoxveganodzywka.png';
import detoxveganszampon from '../assets/products/lines/detox/detoxveganszampon.png';

export const data: IProduct = {
    header: {
        image: header,
        name: "Detox",
        nameDescription: "Linia trychologiczna",
        description: "Stworzona dla problematycznej skóry głowy, a także zwalczająca schorzenia takie jak łupież przewlekły, łuszczyca oraz łojotokowe zapalenie skóry głowy.",
        primaryColor: "sp-detox-green",
        secondaryColor: "sp-detox-light-green",
        circle: {
            color: "#679A69",
            icon: <Leaf color={"#679A69"}/>,
            text1: <>KURACJA<br/>SKÓRY GŁOWY</> as unknown as ReactNode,
            text2: <>REKONSTRUKCJA WĘWNĘTRZNA<br/>I ZEWNĘTRZNA WŁOSÓW</> as unknown as ReactNode,
            text3: <>UZUPEŁNIENIE MASY<br/> I PRZYWRACANIE OBJĘTOŚCI</> as unknown as ReactNode,
        }
    },
    line: {
        title: "PRODUKTY W LINII",
        description: "Nasze produkty są przeznaczone dla profesjonalistów, dlatego nabyć je mogą jedynie salony fryzjerskie. Jednak część z produktów jest również dostępna do pielęgnacji domowej dla klientek.",
        additionalDescription: "* produkty oznaczone gwiazdką są zaliczane do produktów Home Care (do sprzedaży dla klientów).",
        primaryColor: "sp-detox-green",
        secondaryColor: "sp-detox-light-green",
        product1: {
            image: detoxveganszampon,
            title: "Szampon Vegan Detox",
            special: true,
            description: 'Głęboko oczyszcza i detoksykuje skórę głowy, dzięki ekstraktom z zielonej glinki i żeń-szenia. Pomaga kontrolować nadmiar sebum. Stymuluje krążenie krwi i dotlenia skórę. Olejek eteryczny z trawy cytrynowej ma działanie przeciwutleniające, pozostawia włosy niezwykle pachnące.',
            capacity: "230ml",
            primaryColor: "sp-detox-green",

        },
        product2: {
            image: detoxvegangel,
            title: "Żel Vegan Detox",
            special: false,
            description: 'Regeneruje skórę głowy i włosy. Formuła zawiera zieloną glinkę, kompleks czerwonych wodorostów i mieszankę aminokwasów. Przyczyniają się one do zwiększenia integralności włosów i skóry. Może być stosowany na skórę twarzy.',
            capacity: "200g",
            primaryColor: "sp-detox-green",
        },
        product3: {
            image: detoxveganodzywka,
            title: "Odżywka Vegan Detox",
            special: true,
            description: 'Miękkość, aksamitność i odżywienie dla włosów mieszanych i przetłuszczających się z suchymi końcówkami. Przyspiesza odrastanie włosów. Nie zostawia uczucia obciążenia włosów. Zapobiega puszeniu się włosów.',
            capacity: "230g",
            primaryColor: "sp-detox-green",
        },
    },
    productsFormula: {
        ingredients: [
            {
                image: detoxingredient1,
                name: "Glinka zielona",
            },
            {
                image: detoxingredient2,
                name: "Mentol",
            },
            {
                image: detoxingredient3,
                name: "Żeń-szeń",
            },
            {
                image: detoxingredient4,
                name: "Witamina E",
            },
        ]
    },
    comparison: {
        title: "EFEKTY STOSOWANIA",
        content: "Modelka miała problem z przesuszoną skórą głowy i jednocześnie bardzo przetłuszczającą się. Mierzyła się z łupieżem, zaczerwienieniami, oraz swędzeniem i pieczeniem. Już po pierwszym użyciu nieprzyjemne dolegliwości zostały załagodzone, a zaczerwienienia i suche plamy znacznie zmalały.",
        imageBefore: before,
        imageAfter: after,
        primaryColor: "sp-detox-green",
        secondaryColor: "sp-detox-light-green",
        arrowsColor: "#E1EBE1"
    },
    joinUs: {
        title: "CHCESZ MIEĆ LINIĘ DETOX W SWOIM SALONIE?",
        content: "Zarejestruj swój salon i uzyskaj możliwość zakupu produktów oraz dostęp do Akademii Sweet Professional z naszymi autorskimi kursami.",
        primaryColor: "sp-detox-green",
        secondaryColor: "sp-detox-light-green",
    },
    productsClient: {
        title: "",
        description: "",
        additionalDescription: "",
        primaryColor: "",
        secondaryColor: "",
        descriptionPosition: "header",
        products: []
    },
    productsHairdresser: {
        title: "",
        description: "",
        additionalDescription: "",
        primaryColor: "",
        secondaryColor: "",
        descriptionPosition: "header",
        products: []
    }
}
