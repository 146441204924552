import Container from "../Main/BlockElement/Container/Container";
import Section from "../Main/BlockElement/Section/Section";
import Image from "../Main/MediaElement/Image/Image";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";
import background from '../../assets/contact/background.png';
import mobile from '../../assets/contact/mobile.jpg';

export default function ContactHeader() {

    return (
        <Section className="relative">
            <Container className="w-full flex justify-start items-center">
                <Image src={background} alt="Contact Background" className="hidden md:block w-full object-cover" />
                <Image src={mobile} alt="Contact Background" className="block md:hidden w-full object-cover" />
                <Container className="absolute hidden md:flex w-full md:w-2/3 md:h-1/5 items-start justify-start">
                    <Container className="flex flex-col items-stretch justify-center w-full ml-[3dvw]">
                        <Heading type="h1" items={[]} htmlFor={""} className="font-playfair text-md md:text-4xl text-left font-medium pb-2">KONTAKT</Heading>
                        <Paragraph items={[]} htmlFor={""} className="font-playfair text-sm md:text-xl text-left md:mt-5 font-light">
                            Masz dodatkowe pytania lub wątpliwości? Skontaktuj się z nami za pośrednictwem formularza kontaktowego lub wskazanego numeru telefonu.
                        </Paragraph>
                    </Container>
                </Container>
            </Container>

            <Container className="absolute flex md:hidden w-full items-start justify-start">
                <Container className="flex flex-col items-stretch justify-center w-full ml-[3dvw] mt-3">
                    <Heading type="h1" items={[]} htmlFor={""} className="font-playfair text-2xl text-left font-medium pb-2">KONTAKT</Heading>
                    <Paragraph items={[]} htmlFor={""} className="font-playfair text-md text-left font-light">
                        Masz dodatkowe pytania lub wątpliwości? Skontaktuj się z nami za pośrednictwem formularza kontaktowego lub wskazanego numeru telefonu.
                    </Paragraph>
                </Container>
            </Container>

            <Container className="w-full flex justify-end absolute bottom-[-30px]">
                <Container className="p-3 md:p-5 md:px-8 bg-sp-light-pink">
                    <Paragraph className="font-playfair text-md md:text-2xl text-left font-medium text-sp-sweet-pink" items={[]} htmlFor={""}>
                        Jesteśmy tutaj dla Ciebie i <br className="flex md:hidden" /> zawsze  <br className="hidden md:flex" /> chętnie Ci pomożemy!
                    </Paragraph>
                </Container>
            </Container>
        </Section>
    );
}