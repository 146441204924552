import { IProductCard } from "../../../types/IProductCard";
import Article from "../../Main/BlockElement/Article/Article";
import Container from "../../Main/BlockElement/Container/Container";
import Image from "../../Main/MediaElement/Image/Image";
import Heading from "../../Main/TextElement/Heading/Heading";
import Paragraph from "../../Main/TextElement/Paragraph/Paragraph";

export default function ProductCard({ image,title,description,capacity,special,primaryColor }: IProductCard) {
    return (
        <Article className="flex flex-col md:w-1/3 p-10 md:my-15">
            <Image src={image} alt={title} className="w-full shadow"/>
            <Container className={"p-2"}>
                <Heading type="h3" className="font-playfair text-xl md:text-2xl my-5 font-bold" items={[]} htmlFor="">
                    {title}
                    {special === true && (<span className={`text-${primaryColor}`}>*</span>)}
                </Heading>
                <Paragraph className="font-playfair text-sm md:text-md my-3" items={[]} htmlFor="">{description}</Paragraph>
                <Paragraph className={`font-playfair text-sm mt-5 font-bold text-${primaryColor}`} items={[]} htmlFor="" >{capacity}</Paragraph>
            </Container>
        </Article>
    );
}
