import { useState } from "react";
import Container from "../../components/Main/BlockElement/Container/Container";
import Section from "../../components/Main/BlockElement/Section/Section";
import Image from "../../components/Main/MediaElement/Image/Image";
import Page from "../../components/Main/StructureElement/Page/Page";
import Label from "../../components/Main/TextElement/Label/Label";
import { Button } from "../../components/Main/FormElement/Button";
import joinus from '../../assets/joinus/joinus.webp';
import Heading from "../../components/Main/TextElement/Heading/Heading";
import { useNavigate } from "react-router-dom";

export default function SignIn(){
    const [email, setEmail] = useState(''); 
    const [password, setPassword] = useState(''); 
    const navigate = useNavigate();
    
    return(
        <Page className="min-h-[100dvh] flex justify-center items-center">
            <Section className="flex flex-row justify-space-between w-4/5 md:w-3/5">
                <Container className="w-0 md:w-1/2">
                    <Image src={joinus} alt="Dołącz do Nas" className="" />
                </Container>
                <Container className="w-full md:w-1/2 flex flex-col justify-center items-center p-3 md:p-10 bg-sp-light-pink">
                    <Heading type="h1" items={[]} htmlFor="" className="font-playfair text-2xl text-sp-black font-semibold uppercase">Logowanie</Heading>

                    <Container className="w-full flex flex-col gap-2 mt-5">
                        <Label items={[]} htmlFor="email" className="font-playfair text-lg text-sp-black">E-mail</Label>
                        <input name="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full p-2" />
                    </Container>

                    <Container className="w-full flex flex-col gap-2 mt-5">
                        <Label items={[]} htmlFor="password" className="font-playfair text-lg text-sp-black">Hasło</Label>
                        <input name="password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full p-2" />
                    </Container>

                    <Container className="flex flex-col justify-center items-center w-full gap-5 mt-10">
                        <Button className={`bg-sp-sweet-pink text-white hover:bg-opacity-90 border-none py-3 w-full`}>Zaloguj się</Button>
                        <Button className="w-full rounded rounded-3xl border border-sp-dark p-[8px] px-[35px] hover:shadow-lg hover:bg-sp-sweet-pink hover:text-white hover:border-sp-sweet-pink transform transition duration-900 ease-in-out" onClick={() => navigate('/auth/signup')}>Nie masz konta? Zarejestruj się</Button>
                    </Container>
                </Container>
            </Section>
        </Page>
    );
}