import { IProductFeatures } from "../../../types/IProductFeatures";
import Container from "../../Main/BlockElement/Container/Container";

export default function ProductFeatures({circle, secondaryColor, primaryColor}: IProductFeatures) {
    
    const features = [circle.text1, circle.text2, circle.text3].map((el) => (
        <Container className={`flex w-4/12 flex-col md:flex-row items-center justify-center text-left text-xl text-${primaryColor}`}>
            <Container className="flex flex-col items-center justify-center">
                <Container className="w-[50px] md:w-full flex justify-center">{circle.icon}</Container>
            </Container>
            <Container className={"flex flex-col font-lato font-bold text-xs md:text-xl md:m-5 text-left"}>{el}</Container>
        </Container>
    ));

    return <Container className={`flex flex-row items-start justify-space-around w-full bg-${secondaryColor} py-[60px]`}>{features}</Container>;
}
