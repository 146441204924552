import Container from "../../Main/BlockElement/Container/Container";
import Heading from "../../Main/TextElement/Heading/Heading";
import Image from "../../Main/MediaElement/Image/Image";
import { IProductIngredient } from "../../../types/IProductIngredient";

export default function ProductIngredient({ image, name }: IProductIngredient) {
  return (
    <Container className="flex flex-col w-full md:w-1/4 p-10">
      <Image src={image} alt={name} className="w-full" />
      <Container>
        <Heading type="h4" className="font-playfair text-lg my-5 font-medium" items={[]} htmlFor="">{name}</Heading>
      </Container>
    </Container>
  );
}
