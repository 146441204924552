import { IBlockElement } from "../../../../types/IBlockElement";

export default function Nav({
  id,
  className,
  children,
  onClick,
  onChange,
}: IBlockElement) {
  return (
    <>
      <nav id={id} className={className} onClick={onClick} onChange={onChange}>
        {children}
      </nav>
    </>
  );
}
