import {ReactNode} from "react";
import { IProduct } from "../types/IProduct";
import { Heart } from "../components/Product/ProductIcon/Heart/Heart";
import header from '../assets/products/lovely.png';
import hairtreatment from '../assets/products/lines/lovely/hairtreatment.png';
import jedwab from '../assets/products/lines/lovely/jedwab.png';
import kwasyorganiczne from '../assets/products/lines/lovely/kwasyorganiczne.png';
import before from '../assets/products/lines/lovely/lovely1.jpg';
import after from '../assets/products/lines/lovely/lovely2.jpg';
import odzywkahomecare from '../assets/products/lines/lovely/odzywkahomecare.png';
import olejmigdalowy from '../assets/products/lines/lovely/olejmigdalowy.png';
import proteiny from '../assets/products/lines/lovely/proteiny.png';
import szampondeepclean from '../assets/products/lines/lovely/szampondeepclean.png';
import szamponhomecare from '../assets/products/lines/lovely/szamponhomecare.png';
import touchofsilk from '../assets/products/lines/lovely/touchofsilk.png';

export const data: IProduct = {
    header: {
        image: header,
        name: "Lovely",
        nameDescription: "Prostowanie dla każdego",
        description:
            "Lovely Straightening to organiczny system prostowania włosów za pomocą kwasów. Działa poprzez wygładzanie dzięki interakcji molekularnej między wiązaniami w strukturze włosów. Może być stosowany u kobiet w ciąży, oraz na włosy rozjaśniane.",
        primaryColor: "sp-lovely-primary",
        secondaryColor: "sp-lovely-secondary",
        circle: {
            color: "#B5987F",
            icon: <Heart color={"#B5987F"}/>,
            text1: (
                <>
                    PROSTOWANIE
                    <br/>
                    ORGANICZNE
                </>
            ) as unknown as ReactNode,
            text2: (
                <>
                    BEZ SZKODLIWYCH
                    <br/>
                    OPARÓW
                </>
            ) as unknown as ReactNode,
            text3: (
                <>
                    KOMPATYBILNY ZE
                    <br/>
                    WSZYSTKIMI KOSMETYKAMI
                </>
            ) as unknown as ReactNode,
        },
    },
    line: {
        title: "",
        description: "",
        additionalDescription: "",
        primaryColor: "",
        secondaryColor: "",
        product1: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product2: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product3: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
    },
    productsFormula: {
        ingredients: [
            {
                image: proteiny,
                name: "Proteiny",
            },
            {
                image: olejmigdalowy,
                name: "Olej migdałowy",
            },
            {
                image: jedwab,
                name: "Jedwab",
            },
            {
                image: kwasyorganiczne,
                name: "Kwasy organiczne",
            },
        ],
    },
    comparison: {
        title: "EFEKTY STOSOWANIA",
        content:
            "Modelka na zdjęciu jest w 4 miesiącu ciąży i ma włosy rozjaśniane. Potrzebowała bezpiecznego i skutecznego zabiegu trwałego prostowania.",
        imageBefore: before,
        imageAfter: after,
        primaryColor: "sp-lovely-primary",
        secondaryColor: "sp-lovely-secondary",
        arrowsColor: "#F0EAE5",
    },
    joinUs: {
        title: "CHCESZ MIEĆ LINIĘ LOVELY W SWOIM SALONIE?",
        content:
            "Zarejestruj swój salon i uzyskaj możliwość zakupu produktów oraz dostęp do Akademii Sweet Professional z naszymi autorskimi kursami.",
        primaryColor: "sp-lovely-primary",
        secondaryColor: "sp-lovely-secondary",
    },
    productsClient: {
        title: (<span className="text-xl md:text-4xl">PRODUKTY DLA KLIENTEK</span>) as unknown as ReactNode,
        description:
            "Część produktów z linii jest dostosowana do pielęgnacji domowej. Można je zakupić w naszych salonach partnerskich. Dzięki temu każdy może w kompleksowy sposób zadbać o swoje włosy.",
        additionalDescription: "",
        primaryColor: "sp-lovely-primary",
        secondaryColor: "sp-lovely-secondary",
        descriptionPosition: "before",
        products: [
            {
                image: szamponhomecare,
                title: "Szampon Home Care",
                special: false,
                description:
                    "Przeciwko łamaniu się włosów. Zapewnia głębokie czyszczenie, a dzięki kompleksowi aminokwasów naprawia włókna włosów już przy pierwszym zastosowaniu.",
                capacity: "230ml",
                primaryColor: "sp-lovely-primary",
            },
            {
                image: odzywkahomecare,
                title: "Odżywka Home Care",
                special: false,
                description:
                    "Intensywnie naprawia włosy od wewnątrz i na zewnątrz w ciągu 10 minut, a także neutralizuje zasadowości zniszczonych włosów.",
                capacity: "230g",
                primaryColor: "sp-lovely-primary",
            },
        ],
    },
    productsHairdresser: {
        title: "PRODUKTY DO SALONÓW",
        description:
            "Nasze produkty są przeznaczone dla profesjonalistów, dlatego nabyć je mogą jedynie salony fryzjerskie. Jednak część z produktów jest również dostępna do pielęgnacji domowej dla klientek.",
        additionalDescription: "",
        primaryColor: "sp-lovely-primary",
        secondaryColor: "sp-lovely-secondary",
        descriptionPosition: "header",

        products: [
            {
                image: szampondeepclean,
                title: "Szampon Deep Clean 1",
                special: false,
                description:
                    "Otwiera łuski włosów i wspomaga ich oczyszczanie. Zawiera wyjątkową mieszankę ziół,które pomagają chronić skórę głowy.",
                capacity: "980ml",
                primaryColor: "sp-lovely-primary",
            },
            {
                image: hairtreatment,
                title: "Hair Treatment 2",
                special: false,
                description:
                    "Termiczne prostowanie sformułowane z organicznych ekstraktów. Działa poprzez modyfikację wewnętrznej struktury włosów, bez ich łamania. Uzyskuje zdrowe włosy, które odzyskują swoją naturalną formę z upływem czasu. Kompatybilne ze wszystkimi produktami dostępnymi na rynku.",
                capacity: "980g",
                primaryColor: "sp-lovely-primary",
            },
            {
                image: touchofsilk,
                title: "Touch of Silk 3",
                special: false,
                description:
                    "To maska, która tworzy na włosach film ochronny. Może być używana do wzmacniania innych zabiegów: rekonstrukcji, koloryzacji, uszczelniania otwartych łusek lub ochrony włosów. Zapewnia głębokie nawilżenie, ochronę koloru i trwałość prostowania.",
                capacity: "980g",
                primaryColor: "sp-lovely-primary",
            },
        ],
    },
};
