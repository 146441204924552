import { ReactNode } from "react";
import { IProduct } from "../types/IProduct";
import { Crown } from "../components/Product/ProductIcon/Crown/Crown";
import header from '../assets/products/thefirst.jpg';
import kwasalfaliponowy from '../assets/products/lines/thefirst/kwasalfaliponowy.png';
import kwashialuronowy from '../assets/products/lines/thefirst/kwashialuronowy.png';
import kwasglikolowy from '../assets/products/lines/thefirst/kwasglikolowy.png';
import kwasmlekowy from '../assets/products/lines/thefirst/kwasmlekowy.png';
import leavein from '../assets/products/lines/thefirst/leavein.png';
import odzywka from '../assets/products/lines/thefirst/odzywka.png';
import odzywka2 from '../assets/products/lines/thefirst/odzywka2.png';
import szampon from '../assets/products/lines/thefirst/szampon.png';
import szampon2 from '../assets/products/lines/thefirst/szampon2.png';
import szamponwproszku from '../assets/products/lines/thefirst/szamponwproszku.png';
import szamponwproszku2 from '../assets/products/lines/thefirst/szamponwproszku2.png';
import termoochrona from '../assets/products/lines/thefirst/termoochrona.png';
import after from '../assets/products/lines/thefirst/thefirst2.jpg';
import before from '../assets/products/lines/thefirst/thefirst1.jpg';

export const data: IProduct = {
    header: {
        image: header,
        name: "The First",
        nameDescription: "Linia innowacyjna",
        description:
            "Zawiera pierwszy szampon w proszku oraz najszybsze prostowanie na świecie, dzięki innowacyjnemu szamponowi prostującemu. Zaoszczędzi czas w Twoim salonie, optymalizując pracę, zwiększając rozwój biznesu i rentowność. ",
        primaryColor: "sp-tf-primary",
        secondaryColor: "sp-tf-secondary",
        circle: {
            color: "#98B4C2",
            icon: <Crown color={"#98B4C2"}/>,
            text1: (<>15 MINUT</>) as unknown as ReactNode,
            text2: (<>BEZ DYMU</>) as unknown as ReactNode,
            text3: (<>SZYBKA APLIKACJA</>) as unknown as ReactNode,
        },
    },
    line: {
        title: "",
        description: "",
        additionalDescription: "",
        primaryColor: "",
        secondaryColor: "",
        product1: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product2: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product3: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
    },
    productsFormula: {
        ingredients: [
            {
                image: kwasalfaliponowy,
                name: "Kwas alfaliponowy",
            },
            {
                image: kwasglikolowy,
                name: "Kwas glikolowy",
            },
            {
                image: kwasmlekowy,
                name: "Kwas mlekowy",
            },
            {
                image: kwashialuronowy,
                name: "Kwas hialuronowy",
            },
        ],
    },
    comparison: {
        title: "EFEKTY STOSOWANIA",
        content:
            "Klientce zależało na szybkim i bezpiecznym trwałym prostowaniu. Za pomocą jednego produktu uzyskaliśmy widoczny wyżej efekt.",
        imageBefore: before,
        imageAfter: after,
        primaryColor: "sp-tf-primary",
        secondaryColor: "sp-tf-secondary",
        arrowsColor: "#EFF5F8",
    },
    joinUs: {
        title: "CHCESZ MIEĆ LINIĘ THE FIRST W SWOIM SALONIE?",
        content:
            "Zarejestruj swój salon i uzyskaj możliwość zakupu produktów oraz dostęp do Akademii Sweet Professional z naszymi autorskimi kursami.",
        primaryColor: "sp-tf-primary",
        secondaryColor: "sp-tf-secondary",
    },
    productsClient: {
        title: (<span className="text-xl md:text-4xl">PRODUKTY DLA KLIENTEK</span>) as unknown as ReactNode,
        description:
            "Część produktów z linii jest dostosowana do pielęgnacji domowej. Można je zakupić w naszych salonach partnerskich. Dzięki temu każdy może w kompleksowy sposób zadbać o swoje włosy.",
        additionalDescription: "",
        primaryColor: "sp-tf-primary",
        secondaryColor: "sp-tf-secondary",
        descriptionPosition: "before",
        products: [
            {
                image: szampon2,
                title: "The First Szampon",
                special: false,
                description: "Szampon micelarny, który dzięki zawartości kwasu hialuronowego intensywnie nawilża, wygładza włosy i wydłuża efekt wygładzenia. Dodatkowo, skutecznie kontroluje puszenie się włosów, pozostawiając je miękkie oraz gładkie i łatwe do układania",
                capacity: "230ml",
                primaryColor: "sp-tf-primary",
            },
            {
                image: odzywka2,
                title: "The First Odżywka",
                special: false,
                description: "Odżywka z unikalnym składnikiem aktywnym \n" +
                    "N-Dhuance, który kontroluje puszenie się włosów \n" +
                    "i wydłuża efekt wygładzenia.\n",
                capacity: "230g",
                primaryColor: "sp-tf-primary",
            },
            {
                image: leavein,
                title: "The First Leave-in",
                special: false,
                description: "Wszystko, czego potrzebują Twoje włosy: nawilżenie dla poprawy porowatości, ochrona termiczna, filtr przeciwsłoneczny oraz kontrola puszenia.",
                capacity: "150g",
                primaryColor: "sp-tf-primary",
            },
            {
                image: szamponwproszku2,
                title: "Szampon w proszku",
                special: false,
                description: "Pierwszy na świecie szampon oczyszczający \n" +
                    "w proszku do codziennego mycia włosów. Przygotowuje włosy do: koloryzacji, \n" +
                    "rozjaśniania, trwałego prostowania \n" +
                    "i innych zabiegów.",
                capacity: "80g",
                primaryColor: "sp-tf-primary",
            },
        ],
    },
    productsHairdresser: {
        title: (
            <>
                PRODUKTY
                <br/>
                DO SALONÓW
            </>
        ) as unknown as ReactNode,
        description:
            "Nasze produkty są przeznaczone dla profesjonalistów, dlatego nabyć je mogą jedynie salony fryzjerskie. Jednak część z produktów jest również dostępna do pielęgnacji domowej dla klientek.",
        additionalDescription: "",
        primaryColor: "sp-tf-primary",
        secondaryColor: "sp-tf-secondary",
        descriptionPosition: "before",
        products: [
            {
                image: szampon,
                title: "The First Szampon",
                special: false,
                description: "Najszybszy produkt do prostowania włosów \n" +
                    "na świecie, THE FIRST 3.0, opatentowany przez Sweet Professional, składa się z mieszanki kwasów, które w połączeniu z białkami włosów tworzą wiązania chemiczne poprzez interakcję ze źródłem ciepła (prostownicą). 5 organicznych kwasów \n" +
                    "o niesamowitych rezultatach.",
                capacity: "980ml",
                primaryColor: "sp-tf-primary",
            },
            {
                image: szamponwproszku,
                title: "Szampon w proszku",
                special: false,
                description:
                    "Pierwszy na świecie szampon oczyszczający w proszku do codziennego mycia włosów. Przygotowuje włosy do: koloryzacji, rozjaśniania, trwałego prostowania i innych zabiegów.",
                capacity: "400g",
                primaryColor: "sp-tf-primary",
            },
            {
                image: odzywka,
                title: "The First Odżywka",
                special: false,
                description: "Odżywka z unikalnym składnikiem aktywnym \n" +
                    "N-Dhuance, który kontroluje puszenie się włosów \n" +
                    "i wydłuża efekt wygładzenia.\n",
                capacity: "980ml",
                primaryColor: "sp-tf-primary",
            },
            {
                image: termoochrona,
                title: "Termoochrona",
                special: false,
                description: "Ochrona termiczna, filtr przeciwsłoneczny, oraz delikatne odżywienie dla dodatkowej pielęgnacji.",
                capacity: "100ml",
                primaryColor: "sp-tf-primary",
            },
            {
                image: leavein,
                title: "The First Leave-in",
                special: false,
                description: "Wszystko, czego potrzebują Twoje włosy: nawilżenie dla poprawy porowatości, ochrona termiczna, filtr przeciwsłoneczny oraz kontrola puszenia.",
                capacity: "80g",
                primaryColor: "sp-tf-primary",
            },
        ],
    },
};
