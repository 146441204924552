import ProductHeadline from "../ProductHeadline/ProductHeadline";
import Container from "../../Main/BlockElement/Container/Container";
import { IProductsLine } from "../../../types/IProductsLine";
import ProductCard from "../ProductCard/ProductCard";
import Section from "../../Main/BlockElement/Section/Section";
import Animation from "../../Animation/Animation";

export default function ProductsLine({
  title,
  description,
  additionalDescription,
  primaryColor,
  product2,
  product3,
  product1,
}: IProductsLine) {
  return (
    <Animation>
      <Section>
        <Container className="md:mx-40">
          <ProductHeadline
            title={title}
            description={description}
            additionalDescription={additionalDescription}
            primaryColor={primaryColor}
          />
        </Container>
        
        <Container className={"flex flex-wrap md:px-40 flex-col md:flex-row"}>
          <ProductCard {...product1} />
          <ProductCard {...product2} />
          <ProductCard {...product3} />
        </Container>
      </Section>
    </Animation>
  );
}
