import { IDetail } from "../../types/IDetail";
import Container from "../Main/BlockElement/Container/Container";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";

export default function Detail({ icon, title, text }: IDetail) {

    const paragraphs = text.map((element, index) => (
        <Paragraph className="font-lato text-[0.72rem] md:text-sm font-light whitespace-pre-wrap" items={[]} htmlFor="" key={index}>{element}</Paragraph>
    ));

    return(
        <Container className="flex flex-row w-1/2 gap-4">
            <Container className="flex justify-start">{icon}</Container>
            <Container className="flex flex-col items-start">
                <Heading type="h3" items={[]} htmlFor="" className="font-playfair text-l md:text-xl text-sp-black font-[600] mb-2 uppercase">{title}</Heading>
                <Container>{paragraphs}</Container>
            </Container>
        </Container>
    );
}