import { IAboutUs } from "../types/IAboutUs";
import edu from '../assets/aboutus/education.webp';
import innovation from '../assets/aboutus/innovation.webp';
import nature from '../assets/aboutus/nature.webp';
import production from '../assets/aboutus/production.webp';
import we from '../assets/aboutus/we.webp';
import lab from '../assets/aboutus/lab.webp';
import warehouse from '../assets/aboutus/warehouse.webp';

export const us: IAboutUs = {
    history: {
        title: "Historia",
        content:
        "Sweet Professional powstała w Brazylii ponad dekadę temu z misją wydobycia naturalnego piękna włosów oraz wspierania profesjonalistów w branży fryzjerskiej. Dzięki swojemu potencjałowi oraz wysokiej jakości produktom, możemy zaoferować zaawansowane, bezpieczne, innowacyjne i ekskluzywne zabiegi. Wszyscy, którzy współpracują z marką Sweet, posiadają doświadczenia, które się wzajemnie uzupełniają. Marka Sweet została stworzona z misją dostarczania innowacji i nowych doświadczeń dla profesjonalnych fryzjerów. Dzięki temu profesjonaliści mogą pracować bez obaw o kondycję włosów klientek. Marka zdobyła uznanie na pięciu kontynentach i w ponad 70 krajach.",
        image: lab,
        imgPosition: "right",
    },
    polish: {
        title: "Sweet Professional w Polsce",
        content:
        "Euro-fryz s.c. to oficialny importer Sweet Professional oraz spółka cywilna założona w 2001 przez trójkę przyjaciół: Izabelę Dylewską, Sławomira Pożyckiego i Dorotę Falborską. Nieustannie dążymy \ndo tego, aby być na czele branży kosmetycznej poprzez wprowadzanie innowacyjnych rozwiązań. Jesteśmy jedynym oficjalnym importerem brazylijskich kosmetyków profesjonalnych marki Sweet Professional. Od momentu założenia naszej firmy, postawiliśmy sobie za cel oferowanie wyłącznie najwyższej jakości produktów. Zawsze szukaliśmy czegoś, co wyróżnia się spośród standardowej oferty na rynku. Dzięki naszemu zaangażowaniu \ni pasji, udało nam się zbudować firmę, która dostarcza unikalne produkty, spełniające oczekiwania nawet najbardziej wymagających klientów. Eurofryz to synonim jakości, profesjonalizmu innowacyjności w branży kosmetycznej.",
        image: we,
        imgPosition: "left",
    },
    innovation: {
        title: "Innowacja",
        content:
        "Biotechnologia jest wykorzystywana w zabiegach na twarz i ciało od kilku lat będą jedną z najbardziej zaawansowanych technologii na rynku kosmetycznym. W 2011 Sweet Professional dostosowało i opracowało tę technologię, aby mogła być używana do pielęgnacji włosów. Wynikiem tej INNOWACJI było 50 nowych produktów, jakich rynek nigdy wcześniej nie widział.",
        image: innovation,
        imgPosition: "right",
    },
    nature: {
        title: "Natura",
        content:
        "Natura napędza naszą planetę, dlatego stanowi integralną część naszej filozofii. Sweet Professional tworzy swoje produkty, wykorzystując aktywne składniki pochodzenia naturalnego. Szanujemy środowisko, a wszystkie surowce pozyskiwane \nz natury są odpowiednio odnawiane. Woda używana do produkcji naszych kosmetyków jest poddawana oczyszczaniu, aby zapobiec zanieczyszczeniu.",
        image: nature,
        imgPosition: "left",
    },
    education: {
        title: "Edukacja",
        content:
        "Od momentu dołączenia do rodziny Sweet Professional, otrzymasz certyfikowane szkolenie, które pozwoli Ci zdobyć wiedzę na temat produktów oraz rentowności zabiegów. Edukacja jest jedym z naszych kluczowych wartości.",
        image: edu,
        imgPosition: "right",
    },
    production: {
        title: "Własna produkcja",
        content:
        "Sweet Professional dysponuje własnym zespołem chemików oraz zakładem produkcyjnym, w którym każdy produkt przechodzi rygorystyczną kontrolę jakości. Co miesiąc przeprowadzamy ponad 2000 testów, nieustannie doskonaląc nasze zabiegi.",
        image: production,
        imgPosition: "left",
    },
    surprise: {
        title: "Coś co może Cię zaskoczyć....",
        content:
          "Nasze produkty odpoczywają przy dźwiękach muzyki klasycznej i są poddawane chromoterapii. Podłoga \nw fabryce jest różowa, na cześć wszystkich kobiet, które wspieramy i którym pomagamy, zgodnie z naszą filozofią.",
        image: warehouse,
        imgPosition: "right",
    },
};
  