// import BlogShort from "../../components/BlogShort/BlogShort";
import Hero from "../../components/Hero/Hero";
import JoinUs from "../../components/JoinUs/JoinUs";
import Lines from "../../components/Lines/Lines";
import Page from "../../components/Main/StructureElement/Page/Page";
import Quality from "../../components/Quality/Quality";
import SweetAcademyShort from "../../components/SweetAcademyShort/SweetAcademyShort";

export default function Home() {
  return (
    <Page className="w-full pt-[50px] md:pt-0">
      <Hero />
      <Quality />
      <Lines />
      <SweetAcademyShort />
      <JoinUs
        title="DOŁĄCZ DO ŚWIATA SWEET PROFESSIONAL"
        content={`Zarejestruj swój salon i uzyskaj dostęp do Akademii \nSweet Professional z naszymi autorskimi kursami \ni możliwością zakupu produktów.`}
        primaryColor="sp-sweet-pink"
        secondaryColor="sp-light-pink"
      />
      {/* <BlogShort /> */}
    </Page>
  );
}
