import { useParams } from "react-router-dom";
import Container from "../../components/Main/BlockElement/Container/Container";
import Section from "../../components/Main/BlockElement/Section/Section";
import Page from "../../components/Main/StructureElement/Page/Page";
import Heading from "../../components/Main/TextElement/Heading/Heading";
import Image from "../../components/Main/MediaElement/Image/Image";
import { useSearch } from "../../hooks/useSearch";
import { ICard } from "../../types/ICard";

export default function News() {
    const { id } = useParams();
    const { blogs } = useSearch();
    const blog: ICard | undefined = blogs?.find(element => element.id == id);
    if(!blog){ return <Page className="pt-[50px] md:pt-[100px]"></Page>; }

    return (
        <Page className="pt-[50px] md:pt-[100px]">
            <Section className="relative w-full bg-sp-grey h-[45dvh] md:h-[60dvh]">
                <Container className="absolute w-full pt-[50px] flex justify-center items-center">
                    <Container className="flex flex-col items-start gap-8">
                        <Heading type="h1" className="font-playfair md:text-4xl text-2xl text-sp-black text-center font-medium ml-2 md:ml-0" items={[]} htmlFor="">{blog.title}</Heading>
                        <Image src={`data:image/png;base64,${blog.image}`} alt={blog.title} className="" />
                    </Container>
                </Container>
            </Section>
            <Section className="w-full flex justify-center mt-[50px] md:mt-[200px] mb-[100px]">
                <div className="xl:w-1/2 md:w-2/3 flex flex-col gap-5 md:text-xl text-md text-balance ml-2 md:ml-0" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
            </Section>
        </Page>
    );
}
